import {environment} from "../../environments/environment";

export class RegisterUtil {

  static isPhoneEnable(): boolean{
    return environment.isPhoneEnable;
  }

  static isEmailEnable(): boolean{
    return environment.isPhoneEnable ? false : environment.isEmailEnable
  }
}
