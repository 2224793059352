/**
 * Created by cheaphakdey on 29/1/19.
 */

export function SelectFileImage(event: any, callback: Function): void {
  let selectedFiles = event.target.files;
  let reader = new FileReader();
  reader.readAsDataURL(selectedFiles.item(0));
  reader.onload = (_event) => {
    callback(reader.result);
  }
}
