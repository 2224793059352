import {TermsCondition} from './terms-condition';
import {StringFieldUtil} from '../utils/string-field-util';

export class TermConditionList {

  private _termsConditions: TermsCondition[];

  constructor() {
    this._termsConditions = [];
  }

  fromJson(data: TermConditionList){
    if( data[StringFieldUtil.termsAndCondition]) {
      data[StringFieldUtil.termsAndCondition].forEach(value => {
        let termsCondition = new TermsCondition();
        termsCondition.fromJson(value);
        this.termsConditions.push(termsCondition);
      });
    }
  }

  fromJsonBank(data: TermConditionList){
    if( data[StringFieldUtil.termsAndConditionPayment]) {
      data[StringFieldUtil.termsAndConditionPayment].forEach(value => {
        let termsCondition = new TermsCondition();
        termsCondition.fromJson(value);
        this.termsConditions.push(termsCondition);
      });
    }
  }

  fromJsonFaqs(data: TermConditionList){
    if( data["faqs"]) {
      data["faqs"].forEach((value, i) => {
        let termsCondition = new TermsCondition();
        termsCondition.fromJson(value);
        termsCondition.sort = i + 1;
        this.termsConditions.push(termsCondition);
      });
    }
  }

  get termsConditions(): TermsCondition[] {
    return this._termsConditions;
  }

  set termsConditions(value: TermsCondition[]) {
    this._termsConditions = value;
  }

  toMap(){
    const termsConditionsList = [];
    this.termsConditions.forEach((value => {
        termsConditionsList.push(value.toMap());
      }
    ));
    return {
      'terms and condition': termsConditionsList ,
    }
  }

  toMapBank(){
    const termsConditionsList = [];
    this.termsConditions.forEach((value => {
        termsConditionsList.push(value.toMapBank());
      }
    ));
    return {
      'termsAndConditionPayment': termsConditionsList ,
    }
  }

  toMapFAQs(){
    const termsConditionsList = [];
    this.termsConditions.forEach((value => {
        termsConditionsList.push(value.toMapFQAs());
      }
    ));
    return {
      'faqs': termsConditionsList ,
    }
  }
}
