/**
 * Created by cheaphakdey on 19/6/19.
 */

export abstract class PushInfo {
  private _id: string;
  private _storeId: string;
  private _uid: string;
  private _devTokens: string[];
  private _topics: string[];
  private _trigger: string;
  private _type: string;
  private _text: string;
  private _customerId: string;
  private _notificationDate: string;
  private _isSent: boolean;
  private _isRead: boolean;


  get id(): string {
    return this._id;
  }

  set id(value: string) {
    this._id = value;
  }

  get storeId(): string {
    return this._storeId;
  }

  set storeId(value: string) {
    this._storeId = value;
  }

  get uid(): string {
    return this._uid;
  }

  set uid(value: string) {
    this._uid = value;
  }

  get devTokens(): string[] {
    return this._devTokens;
  }

  set devTokens(value: string[]) {
    this._devTokens = value;
  }

  get topics(): string[] {
    return this._topics;
  }

  set topics(value: string[]) {
    this._topics = value;
  }

  get trigger(): string {
    return this._trigger;
  }

  set trigger(value: string) {
    this._trigger = value;
  }

  get type(): string {
    return this._type;
  }

  set type(value: string) {
    this._type = value;
  }

  get text(): string {
    return this._text;
  }

  set text(value: string) {
    this._text = value;
  }

  get customerId(): string {
    return this._customerId;
  }

  set customerId(value: string) {
    this._customerId = value;
  }

  get notificationDate(): string {
    return this._notificationDate;
  }

  set notificationDate(value: string) {
    this._notificationDate = value;
  }

  get isSent(): boolean {
    return this._isSent;
  }

  set isSent(value: boolean) {
    this._isSent = value;
  }

  get isRead(): boolean {
    return this._isRead;
  }

  set isRead(value: boolean) {
    this._isRead = value;
  }
}
